import { createSlice } from '@reduxjs/toolkit'

const user = createSlice({
    name: 'user',
    initialState: {
        isAuth: false,
        userInfo: null,
    },
    reducers: {
        updateAuth: (state, action) => {
            state.isAuth = action.payload
        },
        updateUserInfo: (state, action) => {
            state.userInfo = action.payload
        }
    }
})

const { reducer, actions } = user
export const { updateAuth, updateUserInfo } = actions
export default reducer