import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { updateAuth, updateUserInfo } from 'redux/userSlice';

const PrivateRoute = ({ component: Component, handleChildFunc, ...rest }) => {
  // const { isAuth } = useSelector((state) => state.user);
  const [isAuth, setIsAuth] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem('@token');
    if (!token) {
      setIsAuth(false);
    } else {
      try {
        var dataInfo = jwt_decode(token);
        dispatch(updateUserInfo(dataInfo));
        dispatch(updateAuth(true));
      } catch (err) {
        setIsAuth(false);
      }
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          <Component {...props} handleChildFunc={handleChildFunc} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};
export default PrivateRoute;
