import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice'
import menuReducer from './menuSlice'


const rootReducer = {
    user: userReducer,
    menu: menuReducer,
};

const store = configureStore({
    reducer: rootReducer,
    devTools: false,
});

export default store;
