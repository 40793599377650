import { createSlice } from '@reduxjs/toolkit'

const menu = createSlice({
    name: 'menu',
    initialState: {
        menu: [
            {
                "GroupName": "Chung",
                "GroupLinks": [
                    {
                        "GroupName": "Chung",
                        "Title": "Dashboard",
                        "Path": "/dashboard/index",
                        "Icon": "ni ni-tv-2 text-primary",
                        "Component": "Index",
                        "Show": 1
                    }
                ]
            }],
        isLoading: true,
        isError: false
    },
    reducers: {
        updateMenu: (state, action) => {
            state.menu = action.payload
            state.isLoading = false
            state.isError = false
        },
        updateError: (state, action) => {
            state.isError = action.payload
        }
    }
})

const { reducer, actions } = menu
export const { updateMenu, updateError } = actions
export default reducer