export const API_ENDPOINT = "https://api.mobifone9.net/nms/v1";
// export const API_ENDPOINT = 'http://10.151.120.50:8081/nms/v1'



export const LoginLocal = API_ENDPOINT + "/user/login/local";
export const LoginLdap = API_ENDPOINT + "/user/login/ldap";
export const checkOTP = API_ENDPOINT + "/user/login/otp";
export const getAllUserLocal = API_ENDPOINT + "/user/getAllUser/local";
export const getAllUserLdap = API_ENDPOINT + "/user/getAllUser/ldap";
export const createUserLDAP = API_ENDPOINT + "/user/createUser/ldap";
export const createUserlocal = API_ENDPOINT + "/user/createUser/local";
export const deleteUserByUserIDLDAP = API_ENDPOINT + "/user/deleteUser/ldap";
export const deleteUserByUserIDLocal = API_ENDPOINT + "/user/deleteUser/local";
export const editLdapUser = API_ENDPOINT + "/user/editUser/ldap";

export const createGroupMenu = API_ENDPOINT + "/menu/createGroupMenu";
export const createMenu = API_ENDPOINT + "/menu/createMenu";
export const editMenu = API_ENDPOINT + "/menu/editMenu";
export const deleteMenu = API_ENDPOINT + "/menu/deleteMenu";
export const editGroupMenu = API_ENDPOINT + "/menu/editGroupMenu";
export const deleteGroupMenu = API_ENDPOINT + "/menu/deleteGroupMenu";
export const getMenuByID = API_ENDPOINT + "/menu/getMenuByUserID";
export const getAllGroupMenu = API_ENDPOINT + "/menu/getAllGroup";
export const getAllMenu = API_ENDPOINT + "/menu/getAllMenu";
export const updateStatusMenuByID = API_ENDPOINT + "/menu/updateStatusMenuByID";

export const getAllVPNUser = API_ENDPOINT + "/vpn/getUser";
export const createVPNUserLdap = API_ENDPOINT + "/vpn/createUser/ldap";
export const deleteVPNUser = API_ENDPOINT + "/vpn/deleteUser";
export const sendMailVPN = API_ENDPOINT + "/vpn/sendEmail";
export const getInfoByUser = API_ENDPOINT + "/vpn/getInfoByUser";

export const viewLogServer1 = API_ENDPOINT + "/vpn/viewLog/server1";
export const viewLogServer2 = API_ENDPOINT + "/vpn/viewLog/server2";
export const traceLogging = API_ENDPOINT + "/vpn/traceLog";

export const getOpenVPNUser = API_ENDPOINT + "/vpn/getUserOpen";
export const getOpenVPNGroup = API_ENDPOINT + "/vpn/getGroupOpen";
export const createGroupOpen = API_ENDPOINT + "/vpn/createGroupOpen";
export const deleteGroupOpen = API_ENDPOINT + "/vpn/deleteGroupOpen";
export const createUserOpen = API_ENDPOINT + "/vpn/createUserOpen";
export const deleteUserOpen = API_ENDPOINT + "/vpn/deleteUserOpen";
export const updateUserOpen = API_ENDPOINT + "/vpn/updateUserOpen";

export const getAllGroupPolicy = API_ENDPOINT + "/ca/group/getAll";
export const createGroupPolicy = API_ENDPOINT + "/ca/group/create";
export const updateGroupPolicy = API_ENDPOINT + "/ca/group/update";
export const deleteGroupPolicy = API_ENDPOINT + "/ca/group/delete";
export const getGroupPolicyByID = API_ENDPOINT + "/ca/group/getGroupPolicyByID";

export const getAllUserCA = API_ENDPOINT + "/ca/user/getAll";
export const createUserCA = API_ENDPOINT + "/ca/user/create";
export const updateUserCA = API_ENDPOINT + "/ca/user/update";
export const deleteUserCA = API_ENDPOINT + "/ca/user/delete";
export const changePassUserCA = API_ENDPOINT + "/ca/user/changePass";

export const getAllLog = API_ENDPOINT + "/ca/log/getAll";
export const getLogByUsername = API_ENDPOINT + "/ca/log/getLogByUsername";

export const getAllClient = API_ENDPOINT + "/ca/client/getAll";
export const createClient = API_ENDPOINT + "/ca/client/create";
export const updateClient = API_ENDPOINT + "/ca/client/update";
export const deleteClient = API_ENDPOINT + "/ca/client/delete";

export const getAllPermission = API_ENDPOINT + "/ca/permission/getAll";
export const createPermission = API_ENDPOINT + "/ca/permission/create";
export const updatePermission = API_ENDPOINT + "/ca/permission/update";
export const deletePermission = API_ENDPOINT + "/ca/permission/delete";

export const getFttxClient = API_ENDPOINT + "/fttx/client/getAll";
export const createFttxClient = API_ENDPOINT + "/fttx/client/create";
export const updateFttxClient = API_ENDPOINT + "/fttx/client/update";
export const deleteFttxClient = API_ENDPOINT + "/fttx/client/delete";





export const getFttxDevice = API_ENDPOINT + "/fttx/device/getAll";
export const createFttxDevice = API_ENDPOINT + "/fttx/device/create";
export const updateFttxDevice = API_ENDPOINT + "/fttx/device/update";
export const deleteFttxDevice = API_ENDPOINT + "/fttx/device/delete";


export const traceLogOpenVPN = API_ENDPOINT + "/openvpn/log/traceLog";
export const getUUIDOpenVPN = API_ENDPOINT + "/openvpn/log/getUUID";
export const deleteUUIDOpenVPN = API_ENDPOINT + "/openvpn/log/deleteUUID";
export const addUUIDOpenVPN = API_ENDPOINT + "/openvpn/log/addUUID";

export const getInfoByIP = API_ENDPOINT + "/ipam/web/getInfoByIP";

/*-----------------------------------------*/
export const getAllDevice = API_ENDPOINT + "/networkDevice/getAllActiveDevice";
export const editDevice = API_ENDPOINT + "/networkDevice/editDevice";
export const createDevice = API_ENDPOINT + "/networkDevice/createDevice";
export const deleteDevice = API_ENDPOINT + "/networkDevice/deleteDevice";
export const decryptPassword = API_ENDPOINT + "/networkDevice/decryptPassword";
export const getAllBackupDevice = API_ENDPOINT + "/networkDevice/getAllBackupLogs";

export const downloadFile = API_ENDPOINT + "/networkDevice/downloadBackup";

export const backupDevice = API_ENDPOINT + "/networkDevice/backupDeviceById";
export const backupAllDevice = API_ENDPOINT + "/networkDevice/backupAllDevice";


