import '@fortawesome/fontawesome-free/css/all.min.css';
import App from 'App';
import 'assets/plugins/nucleo/css/nucleo.css';
import 'assets/scss/argon-dashboard-react.scss';
// import 'assets/vendor/font-awesome/css/font-awesome.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store from 'redux/store';

ReactDOM.render(
  <Provider store={store}>
    <App />
    <ToastContainer />
  </Provider>,
  document.getElementById('root')
);
