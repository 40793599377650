import axios from 'axios';
import moment from 'moment';


// ===============================================================================
// GET API RESOURCE
// eslint-disable-next-line
function getJsonKey() {
}

export function getJsonToken() {
    const token = localStorage.getItem('@token')
    if (token) {
        return token !== undefined ? token : null;
    }
    return null
}

export function getJsonLog() {
    return {
        channel: 'APP_MMS',
        imei: '',
        key: '',
        lang: '',
        lat: '',
        lng: '',
        model: '',
        os: 'win32',
        time: moment().format('YYYYMMDDHHmmss'),
        version: '1.0.0',
    };
}

// eslint-disable-next-line
function getDeviceLat() {
    return '';
}

// eslint-disable-next-line
function getDeviceLng() {
    return '';
}

// eslint-disable-next-line
function getDeviceModel() {
    return '';
}

// eslint-disable-next-line
function getDeviceImei() {
    return '';
}

/**
 * Hàm gọi API. Đã cấu hình sẵn các tham số, chỉ cần truyền url và form data
 * @param {Object} configs Cấu hình gọi API (Xem tại: ...)
 * @returns [error, response]
 */
export async function cty9ExecApi(configs) {
    if (!configs) throw new Error('Missing "configs"!');
    if (!configs.url) throw new Error('Missing "configs.url"!');

    try {
        const token = getJsonToken();
        const jsonData = {
            jsonData: configs.data || {},
            jsonLog: getJsonLog(),
        };

        // console.log('@POST: ' + configs.url);
        // console.log(JSON.stringify(jsonData, null, 2));


        const resp = await axios.post(configs.url, JSON.stringify(jsonData), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            }
        });
        if (resp.data.code === '0000') {
            return [null, resp.data];
        } else {
            return [resp.data.mess, null];

        }
    } catch (error) {
        return [error, null];
    }
}
